<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn
        to="/admin/assistant_teacher_report/personnel_temporary_assistant_period"
      >
        <span>รอบบรรจุ ครูผู้ช่วย</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
      <v-btn
        to="/admin/assistant_teacher_report/manage_assistant_teacher_committee"
      >
        <span>เสนอคณะกรรมการ</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>
      <v-btn to="/admin/assistant_teacher">
        <span>การประเมิน/ตรวจสอบ</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

   <!--    <v-btn to="/admin/assistant_teacher_detail">
        <span>การประเมิน/ตรวจสอบ </span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn> -->

      <v-btn to="/admin/assistant_teacher_report">
        <span>รายงานผลการประเมิน</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn> 
    </v-bottom-navigation>
    <v-bottom-navigation color="info" horizontal  dark>
      <v-btn
        to="/admin/manage_assistant_teacher"
      >
        <span>ครูผู้ช่วย</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>    
    </v-bottom-navigation>

  </div>
</template>

<script>
export default {
  name: "HRvecPersonnelmenu",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
  computed: {
    color() {
      return "#202224";
    }
  }
};
</script>

<style lang="scss" scoped></style>
