<template>
  <div>
    <base-material-card
      icon="mdi-clipboard-text"
      title="การเสนอคณะกรรมการประเมิน ตำแหน่งครูผู้ช่วย"
      class="px-5 py-3"
    >
      <v-card class="mb-4 pa-2">
        <v-row no-gutters>
          <!--  <v-col cols="12" md="2" class="pa-1">
                <div>
                  <v-autocomplete
                    outlined
                    label="รอบ"
                    :items="group_mt_times"
                    item-text="mt_times"
                    item-value="mt_times"
                    @change="checkTimeYear()"
                    v-model="dateTimeAppSelect"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="pa-1">
                <div>
                  <v-autocomplete
                    outlined
                    label="ปี"
                    :items="group_mt_years"
                    item-text="mt_years"
                    item-value="mt_years"
                    @change="checkTimeYear()"
                    v-model="dateYearAppSelect"
                  ></v-autocomplete>
                </div>
              </v-col>              -->
          <v-col cols="12" md="12" class="pa-1">
            <div class="text-right">
              <v-btn
                :href="
                  '#/admin/print_assistantcommittee/?mt_appoin_day=' +
                    mt_appoin_day +
                    '&mt_appoin_month=' +
                    mt_appoin_month +
                    '&mt_appoin_year=' +
                    mt_appoin_year
                "
                target="_blank"
                rounded
                color="info"
              >
                <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อกรรมการประเมิน (1.1)
                รวม</v-btn
              >

              <v-btn
                :href="
                  '#/admin/print_assistantcommittee_vip/?mt_appoin_day=' +
                    mt_appoin_day +
                    '&mt_appoin_month=' +
                    mt_appoin_month +
                    '&mt_appoin_year=' +
                    mt_appoin_year
                "
                target="_blank"
                rounded
                color="info"
              >
                <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อผู้่ทรงคุณวุฒิ (2.1)
                รวม</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-center">จำนวนทั้งสิ้น</th>
              <th class="text-center">เสนอกรรมการ</th>
              <th class="text-center">แนบไฟล์เสนอ</th>
              <th class="text-center">ขอยกเลิกรายการ</th>
              <th class="text-center">ยังไม่ได้ดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <v-btn
                  @click="manage_assistant_teacherQueryTimeYear()"
                  fab
                  color="info"
                  >{{ dashboardShow.count_teach_alls }}
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CF'))
                  "
                  fab
                  color="warning"
                  >{{ dashboardShow.count_teach_cfs }}
                </v-btn>
              </td>

              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CK'))
                  "
                  fab
                  color="success"
                  >{{ dashboardShow.mt_committe_files }}
                </v-btn>
              </td>

              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CC'))
                  "
                  fab
                  color="red"
                  >{{ dashboardShow.count_teach_ccs }}
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'null'))
                  "
                  fab
                  color="warning"
                  >{{ dashboardShow.count_teach_nulls }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <!-- College CC -->
      <v-card>
        <v-data-table
          :headers="headerscc"
          :items="manage_assistant_teacherscc"
          class="elevation-1"
          :loading="loading"
         
        >
          <template v-slot:item.mt_admissions_status="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_admissions_status === 'missing'"
            >
              <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
            </v-chip>
            <v-chip
              dark
              color="primary"
              v-if="item.mt_admissions_status === 'receipt'"
            >
              <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
            </v-chip>
          </template>

          <template v-slot:item.mt_committe_status="{ item }">
            <div v-if="item.mt_committe_status === 'CC'">
              <v-chip @click="updateAssisstant(item.mt_id)" color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งรายงานข้อมูลผิดพลาด</v-chip
              >
            </div>
            <div v-if="item.mt_committe_status === 'CF'">
              <v-chip color="success" dark>
                <v-icon>mdi-information-outline</v-icon>
              </v-chip>
            </div>
          </template>

        

          <template v-slot:item.mt_committe_file="{ item }">
            <div v-if="item.mt_committe_file">
              <v-btn
                @click="viewpdfmt_committe_file(item.mt_committe_file)"
                color="info"
                fab
                small
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
            </div>
          </template>

          <template v-slot:item.mt_committe_datetime="{ item }">
            <div v-if="item.mt_committe_datetime">
              {{
                item.mt_committe_datetime
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY : HH:mm:ss a")
              }}
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
            </div>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card>

      <!-- Query Normal -->
     
      <v-data-table
       :headers="showHeaders"
        :items="manage_assistant_teachers"
        class="elevation-1"
        :loading="loading"
        :search="search"
      >
      <template v-slot:top>
        <div>
          <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
        filled
      />
        </div>
        <div>
          <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        </div>
      </template>
        <template v-slot:item.mt_committe_status="{ item }">
          <div v-if="item.mt_committe_status === 'CC'">
            <v-chip @click="updateAssisstant(item.mt_id)" color="red" dark>
              <v-icon>mdi-information-outline</v-icon>
              แจ้งรายงานข้อมูลผิดพลาด</v-chip
            >
          </div>
          <div v-else-if="item.mt_committe_status === 'CF'">
            <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
          </div>
          <div v-else>
            <v-icon color="red">mdi-alert</v-icon>
          </div>
        </template>

        <template v-slot:item.mt_committe_file="{ item }">
          <div v-if="item.mt_committe_file">
            <v-btn
              @click="viewpdfmt_committe_file(item.mt_committe_file)"
              color="info"
              fab
              small
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-icon color="red">mdi-alert</v-icon>
          </div>
        </template>

        <template v-slot:item.mt_committe_datetime="{ item }">
          <div v-if="item.mt_committe_datetime">
            {{
              item.mt_committe_datetime
                | moment("add", "543 years")
                | moment("D MMMM YYYY : HH:mm:ss a")
            }}
          </div>
          <div v-else>
            <v-icon color="red">mdi-alert</v-icon>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
          v-if="item.mt_committe_ipaapprove==='pass'"
            fab
            small
            color="success"
            @click.stop="updatemanage_assistant_teacher(item.mt_id)"
            ><v-icon>mdi-update</v-icon></v-btn
          >
          <v-btn
          v-else-if="item.mt_committe_ipaapprove==='dontpass'"
            fab
            small
            color="red"
            @click.stop="updatemanage_assistant_teacher(item.mt_id)"
            ><v-icon>mdi-update</v-icon></v-btn
          >

          <v-btn
          v-else
            fab
            small
            color="warning"
            @click.stop="updatemanage_assistant_teacher(item.mt_id)"
            ><v-icon>mdi-update</v-icon></v-btn
          >
          
        </template>

        <template v-slot:item.cancels="{ item }">
          <v-icon color="red" @click.stop="evaluate_assistant(item.id_card)">
            mdi-cancel
          </v-icon>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </base-material-card>

    <!-- V-model pdfmt_committe_filedialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdfmt_committe_filedialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRcommitteeAssistantTeach/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import { constants } from 'crypto';
export default {
  name: "HRvecManageAssistantTeacherCommittee",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      search: "",
      college_select: "",
     singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },
        { text: "ดำเนินการ", align: "center", value: "actions" },
        {
          text: "สถานะกรรมการ",
          align: "center",
          value: "mt_committe_status"
        },
        {
          text: "วันที่บรรจุ",
          align: "center",
          value: "mt_appoin"
        },
        
        {
          text: "เอกสารแนบ",
          align: "center",
          value: "mt_committe_file"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "mt_committe_datetime"
        }
      ],
      headerscc: [
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },
        {
          text: "สถานะกรรมการ",
          align: "center",
          value: "mt_committe_status"
        },
        {
          text: "วันที่บรรจุ",
          align: "center",
          value: "mt_appoin"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "mt_collegeCC_datail"
        },

        {
          text: "เอกสารแนบ",
          align: "center",
          value: "mt_committe_file"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "mt_committe_datetime"
        }
      ],

      group_mt_years: [],
      group_mt_times: [],
      manage_assistant_teacherscc: [],
      manage_assistant_teachers: [],
      dateTimeAppSelect: "",
      dateYearAppSelect: "",
      dashboardShow: [],
      editmanage_assistant_teacher: [],
      pdf_files: [],
      pdfmt_committe_filedialog: false,
      editPersonnel:{},
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {   
    await this.checkParamQuery();    
    await this.pertem_assis_teachASCCQueryAll();
  },
  methods: {
    async checkParamQuery() {
      let appoin_day = this.mt_appoin_day;
      let appoin_month = this.mt_appoin_month;
      let appoin_year = this.mt_appoin_year;    
      if (appoin_year > 0 && appoin_month > 0 && appoin_day) {
        await this.manage_assistant_teacherQueryTimeYear();        
      } else {
        
      }
    },
    async updatemanage_assistant_teacher(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;

      Swal.fire({
        title: "ต้องการยืนยันอนุมัติการตรวจสอบรายการนี้ ?",
        text: textAlert,
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ถูกต้อง",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ไม่ถูกต้อง`
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
          this.editmanage_assistant_teacher.mt_committe_ipaapprove = "pass";

          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );
          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            
            await this.manage_assistant_teacherQueryTimeYear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isDenied) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
          let resultdel = "";
          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRcommitteeAssistantTeach/" +
              this.editmanage_assistant_teacher.mt_committe_file
          });

          this.editmanage_assistant_teacher.mt_collegeCC = "";
          this.editmanage_assistant_teacher.mt_committe_status = "";
          this.editmanage_assistant_teacher.mt_committe_file = "";
          this.editmanage_assistant_teacher.mt_committe_datetime = "";          
          this.editmanage_assistant_teacher.mt_committe_ipaapprove = "dontpass";
        
          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );
          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.pertem_assis_teachASCCQueryAll();
            await this.manage_assistant_teacherQueryTimeYear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async viewpdfmt_committe_file(mt_committe_file) {
      this.pdf_files = mt_committe_file;
   
      this.pdfmt_committe_filedialog = true;
    },

    async pertem_assis_teachASCCQueryAll() {
      this.loading = true
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_committe_status: "CC"
      }).finally(() => (this.loading = false))
      this.manage_assistant_teacherscc = result.data;
      
    },

    async updateAssisstant(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
console.log(result.data)
      Swal.fire({
        title: "ต้องการอนุมัติการขอยกเลิกรายการ ?",
        text: textAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
      
          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.editmanage_assistant_teacher.mt_committe_file
          });

          this.editmanage_assistant_teacher.mt_collegeCC = "";
          this.editmanage_assistant_teacher.mt_committe_status = "";
          this.editmanage_assistant_teacher.mt_committe_file = "";
          this.editmanage_assistant_teacher.mt_committe_datetime = "";
          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );

          this.editPersonnel.ApiKey=this.ApiKey
          this.editPersonnel.id_card = this.editmanage_assistant_teacher.mt_id_card;
          this.editPersonnel.pertem_assistantFileCC = "";        
          this.editPersonnel.pertem_assistantFile = "";




          let resultpt = await this.$http.post(
        "personnel_temporary.update.php",
        this.editPersonnel
      );

 console.log(resultpt.data)

          if (result.data.status == true && resultpt.data.status==true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });

            await this.pertem_assis_teachASCCQueryAll();
            await this.manage_assistant_teacherQueryTimeYear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async manage_assistant_teacherShow(mt_committe_status) {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_appoin_day: this.mt_appoin_day,
          mt_appoin_month: this.mt_appoin_month,
          mt_appoin_year: this.mt_appoin_year,
          mt_admissions_status: "receipt",
          mt_committe_status: mt_committe_status,
          showSystem:"ok",

        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      
    },

    async manage_assistant_teacherQueryTimeYear() {
    
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_appoin_day: this.mt_appoin_day,
          mt_appoin_month: this.mt_appoin_month,
          mt_appoin_year: this.mt_appoin_year,
          mt_admissions_status: "receipt",
          showSystem:"ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let count_teach_alls = 0;
      let count_teach_ccs = 0;
      let count_teach_nulls = 0;
      let count_teach_cfs = 0;
      let mt_committe_files = 0;
      data.forEach(async value => {
        if (value.mt_admissions_status == "receipt") {
          count_teach_alls += 1;
        }

        if (value.mt_committe_status == "CF") {
          count_teach_cfs += 1;
        } else if (value.mt_committe_status == "CC") {
          count_teach_ccs += 1;
        } else {
          count_teach_nulls += 1;
        }

        if (value.mt_committe_file) {
          mt_committe_files += 1;
        }
      });
      this.dashboardShow.count_teach_alls = count_teach_alls;
      this.dashboardShow.count_teach_ccs = count_teach_ccs;
      this.dashboardShow.count_teach_nulls = count_teach_nulls;
      this.dashboardShow.count_teach_cfs = count_teach_cfs;
      this.dashboardShow.mt_committe_files = mt_committe_files;    
    },
    async timesAssisstant() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        group_mt_times: "Ok"
      });
      this.group_mt_times = result.data;
    },
    async yearAssisstant() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        group_mt_years: "Ok"
      });
      this.group_mt_years = result.data;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    mt_appoin_day() {
      return this.$route.query.appoin_day;
    },
    mt_appoin_month() {
      return this.$route.query.appoin_month;
    },
    mt_appoin_year() {
      return this.$route.query.appoin_year;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>

<style lang="scss" scoped></style>
